import React from "react"

import styles from "./description-text.module.scss"

function DescriptionText(props) {
  return (
    <div className={styles.text}>
      {props.text.split("\n").map((item, key) => {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        )
      })}
    </div>
  )
}

DescriptionText.propTypes = {}

DescriptionText.defaultProps = {}

export default DescriptionText
