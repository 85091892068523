import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import TitleText from "../components/text/title-text/title-text"
import DescriptionText from "../components/text/description-text/description-text"

import styles from "./equipo-humano.module.scss"

function TeamPage() {
  const data = useStaticQuery(graphql`
    query {
      raul: file(relativePath: { eq: "team/raul.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      inma: file(relativePath: { eq: "team/inma.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Equipo humano" />
      <TitleText text="Equipo humano" />
      <DescriptionText
        text={`Personal profesional de la Clínica Veterinaria Meco`}
      />
      <div className={styles.team}>
        <div className={styles.vet}>
          <Img
            className={styles.photo}
            fluid={data.raul.childImageSharp.fluid}
          />
          <p className={styles.description}>
            Raúl de las Heras<br></br>Veterinario
          </p>
        </div>
        <div className={styles.vet}>
          <Img
            className={styles.photo}
            fluid={data.inma.childImageSharp.fluid}
          />
          <p className={styles.description}>
            Inmaculada Godino<br></br>ATV
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default TeamPage
